import WTwitterFollowComponent from '@wix/thunderbolt-elements/src/components/WTwitterFollow/viewer/WTwitterFollow';


const WTwitterFollow = {
  component: WTwitterFollowComponent
};


export const components = {
  ['WTwitterFollow']: WTwitterFollow
};


// temporary export
export const version = "1.0.0"
